<style type="text/css">
    @import "~@/assets/css/common.css";
    @import "~@/assets/css/admin.css";
</style>
<script>
    import Layout from "@/views/layouts/main";
    import CheckHeader from "@/components/check-header";
    import CertHeader from "./cert-header";
    import {
        getZskInfo,
        clearZsk
    } from "@/api/zhengshu/manage.js"

    /**
     * 证书库导入--数据上传及匹配

     */
    export default {
        components: {
            Layout,
            CheckHeader,
            CertHeader,
        },
        data() {
            return {
                title: "证书导入 ",
                title2: "",
                items: [{
                    text: "用户首页",
                    href: '/admin'
                },
                    {
                        text: "证书导入",
                        active: true
                    }
                ],
                module: "ZSK",
                importTitle: "证书导入",
                excelForm: {
                    '姓名': 'xm',
                    '性别': 'xb'
                },
                zskid: "",
                zskmc: "",
                addForm: {
                    uploadTime: ""
                },
                ksbmbh: "",
            };

        },

        methods: {
            // 获取详情
            getDeatails(zskid) {
                getZskInfo(zskid).then(res => {
                    if (res.status) {
                        this.addForm = res.data
                        this.ksbmbh = res.data.ksbmbh
                        this.title2 = res.data.zskmc ? res.data.zskmc : ""
                    }
                })

            },
            // 完成匹配跳转
            submit() {
                this.$refs.header.getUrl('/admin/cerImportLogistics')
            },


            // 证书库上传
            uploadZkzk() {
                var _this = this;
                _this.$importModal().show({
                    title: _this.importTitle,
                    module: _this.module,
                    ksbmbh: _this.ksbmbh,
                    zskid: _this.zskid,
                    success: _this.success
                })
            },
            success() {
                this.getDeatails(this.zskid)
            },
            clearZkz() { //清空证书库
                this.$confirm(`是否确认将"${this.addForm.zskmc}"证书库清空?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (this.addForm.uploadSize!=0) {
                        clearZsk(this.zskid).then(res => {
                            if (res.status) {
                                this.$message({
                                    type: 'success',
                                    message: '当前证书库清空成功!'
                                });
                            }
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            message: '暂无可清空数据!'
                        });
                    }

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消清空'
                    });
                });
            }
        },
        mounted() {
            if (this.zskid) {
                this.getDeatails(this.zskid)
            }
        },
        created() {
            this.zskid = this.$route.query["zskid"] ? this.$route.query["zskid"] : "";
        }
    };
</script>

<template>
    <Layout>
        <CheckHeader :title="title" :title2="title2" :items="items"/>
        <CertHeader ref="header"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="min-height: 460px;">
                    <div class="card-body">
                        <h4 class="card-title">请上传证书并对导入字段进行匹配对应</h4>
                        <div class="outerCotent mt-3 ">
                            <div class="outerCotent mt-3 ">
                                <div class="msgContent flexList fs-xs">
                                    <div class="flexList w-100 msgLabel ">
                                        <div class="msgName ">上传库结构
                                        </div>
                                        <div class="msgVal selectShow flexList">
                                            <a :href="this.API_URL + '/yethan/register/downloadTemplate/' + this.module  + '?ytoken=' + this.getStore(
                      'accessToken')" target="_blank">
                                                <div class="blue-font">
                                                    点击下载证书上传库结构模板.dbf
                                                </div>
                                            </a>


                                        </div>
                                    </div>
                                    <div class="flexList w-100 msgLabel ">
                                        <div class="msgName ">字段说明</div>
                                        <div class="msgVal selectShow  flexList w-33">
                                            <a :href="this.API_URL + '/yethan/cert/bank/downFieldExplain' + '?ytoken=' + this.getStore(
          'accessToken')" target="_blank">
                                                <div class="blue-font">
                                                    点击下载证书上传库字段说明.xlsx
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                    <div class="flexList w-100 msgLabel">
                                        <div class="msgName ">证书库上传</div>
                                        <div class="msgVal selectShow flexList">
                                            <el-button size="small" type="primary" plain class="flexList mr-2 h30  "
                                                       @click="uploadZkzk"><i
                                                    class="iconfont icon-riLine-upload-2-line mr-2"></i>证书库上传
                                            </el-button>
                                            <span class="mr-3">已上传：<span
                                                    class="text-info">{{addForm.uploadSize?addForm.uploadSize:0}}</span>条记录</span>
                                            <span class="mr-3">导入人：{{addForm.uploadUser}}</span>
                                            <span class="mr-3">导入时间：{{addForm.uploadTime}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="flexList w-100 msgLabel">
                                      <div class="msgName ">证书库名</div>
                                      <div class="msgVal selectShow flexList">
                                        <div class="msgValInput w-30 pl-2  flexList" title="证书库名,一般不可修改">
                                          {{addForm.zkzkmc}}
                                        </div>
                                      </div>
                                    </div> -->
                                    <!--                   <div class="flexList w-100 msgLabel">-->
                                    <!--                    <div class="msgName ">清空证书库</div>-->
                                    <!--                    <div class="msgVal selectShow flexList">-->
                                    <!--                      <el-button size="small" type="primary" plain class="flexList mr-2 h30  " @click="clearZkz">清空证书库-->
                                    <!--                      </el-button>-->
                                    <!--                    </div>-->
                                    <!--                  </div>-->
                                    <div class="flexList w-100 msgLabel" style="height: 60px;">
                                        <div class="msgName"></div>
                                        <div class="msgVal flexList">
                                            <button class="btn btn-info h35 mr-2 sub-btn" @click="submit">已完成字段匹配，下一步
                                            </button>
                                            <a href="javascript:history.back()">
                                                <button class="btn btn-secondary h35 mr-2 ">返回上一步</button>
                                            </a>
                                            <el-button size="small" type="primary" class="flexList mr-2 h35  "
                                                       @click="clearZkz">清空证书库
                                            </el-button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!--  <importFields module="abc" :excelFields='["姓名", "性别"]' v-model="excelForm" :isImport="false" class="mb-2">
                            </importFields>
                            <exportFields module="abc" :excelFields='["姓名", "性别"]' v-model="excelForm" :isImport="false" class="mb-2">
                            </exportFields> -->
                            <!-- <div class="flexList w-100 msgLabel" style="height: 60px; border-bottom: 1px solid #DFDFDF;">
                              <div class="msgVal col-lg-4">{{excelForm}}</div>
                              <div class="msgVal flexList">
                                <a href="/admin/scoreImportTime"><button
                                    class="btn btn-info h35 mr-2 sub-btn">已完成字段匹配，下一步</button></a>
                              </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!-- 弹窗开始 -->

        <!-- 弹窗结束 -->
    </Layout>
</template>
